import { useSelector } from "react-redux";

export const ToastProgress = () => {
    const { show, message } = useSelector(state => state.toastProgressSlice);

    return (
        show &&
        <div className="toast-custom toast-success animate__animated animate__pulse" role="alert">
            <div className="spinner-border text-success me-2" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
            {message}
        </div>
    );
};