import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import CreateDocument from './ModalDocument';
import ListDocument from './ListDocument';
import BreadcrumbCustom from "../../../app/components/BreadcrumCustom";

import { cleanData, searchFromParatrizeDocuments, setListParametrizeDocuments } from "../../../reducers/documents/reducerGenerateDocument";
import { SearchBar } from "../../../app/components/SearchBar";
import { ModalRoles } from "./ModalRoles";
import GeneralService from "../../../services/GeneralService";
import { setRole } from "../../../reducers/roles/reducerRoles";

const DocumentsAuto = () => {
    const [show, setShow] = useState(false);
    const [showModalRoles, setShowModalRoles] = useState(false);
    const dispatch = useDispatch();

    const getDocument = async () => {
        const service = new GeneralService("documents-auto");
        const res = await service.getList(1000);
        const { documents = [] } = res;
        dispatch(setListParametrizeDocuments({ value: documents }));
    }

    const getRoles = async () => {
        const service = new GeneralService("roles");
        const res = await service.getList(0);
        const { roles = [] } = res;
        dispatch(setRole(roles));
    }

    useEffect(() => {
        getDocument();
        getRoles();
    }, []);

    return (
        <div className="container-fluid max-height-overflow-y">
            <div className="row">
                <div className="col">
                    <BreadcrumbCustom
                        title="Documentos"
                        activeRoute="Listar documentos"
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-5 mt-1">
                    <SearchBar onChange={(e) => dispatch(searchFromParatrizeDocuments({ value: e.target.value }))} />
                </div>
                <div className="col-4">
                    <button title="Crear nuevo documento" className="btn btn-success rounded-pill mt-1" type="button" onClick={() => { dispatch(cleanData()); setShow(true) }}>
                        <i className="fa-solid fa-file-signature text-white me-2" />
                        Crear
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="table-data">
                        <ListDocument setShow={setShow} setShowModalRoles={setShowModalRoles} />
                    </div>
                </div>
            </div>

            <CreateDocument show={show} setShow={setShow} />
            <ModalRoles show={showModalRoles} setShow={setShowModalRoles} />
        </div >
    )
};

export default DocumentsAuto;