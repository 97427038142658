import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import service from "../../UtilitiesCodeTypePeople/service";
import { reducerForm } from "../../../reducers/people/reducerPeople";
import ModalUtilitiesCodeTypePeople from "./ModalUtilitiesCodeTypePeople";
import { setList } from "../../../reducers/configuration/utilitiesCodeTypePeopleSlice";
import { filterUtilitiesCodeTypePeople } from "../../UtilitiesCodeTypePeople/utils";
import { useEffect } from "react";
import { TOAST_WARNING, ID_NOT_DEFINED } from "../../../constant/constant";
import { toast } from "../../../utils/toast";

const TabCodesUtilitiesTypePeople = () => {
    const { list } = useSelector(state => state.typepeopleSlice);
    const { dataTypePeole } = useSelector(state => state.peopleSlice);
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [hasCodes, setHasCodes] = useState(false);

    const loadUtilities = async () => {
        if (parseInt(dataTypePeole.type_people_id) !== ID_NOT_DEFINED) {
            const res = await service.getList(dataTypePeole.type_people_id);
            const { utilities_code_type_people = [] } = res;
            if (utilities_code_type_people.length > 0) {
                setHasCodes(true);
            } else {
                toast(dispatch, "El tipo de persona aún no tiene códigos configurados", TOAST_WARNING);
                setHasCodes(false);
            }
            const fields = filterUtilitiesCodeTypePeople(list.find(e => e.id === parseInt(dataTypePeole.type_people_id)), utilities_code_type_people);
            dispatch(setList({ value: fields }));
            return;
        }
        dispatch(setList({ value: [] }));
    }

    useEffect(() => {
        loadUtilities();
    }, [dataTypePeole.type_people_id]);

    const setData = async (e) => {
        dispatch(reducerForm({
            key: e.target.name,
            value: e.target.value,
        }));
    }

    return (
        <>
            <div className="row center-vertical">
                <div className="col">
                    <h5 className="font-lato">
                        <i className="fa-solid fa-toolbox me-2"></i>
                        Datos de contacto
                    </h5>
                </div>
                <div className="col">
                    <select
                        className="form-select mt-2"
                        name="type_people_id"
                        value={dataTypePeole.type_people_id}
                        onChange={setData}
                    >
                        <option value="0">
                            Seleccione tipo de persona
                        </option>
                        {
                            list.map(item => (<option value={item.id} key={item.id}>
                                {item.name}
                            </option>))
                        }
                    </select>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col">
                    <p>Indica los campos que corresponden a los datos de contacto para cada tipo de persona <b>Nombre</b>, <b>Email</b>.</p>
                </div>
                <div className="col">
                    <button title="Abre el modal para configurar los códigos de las utilidades" className={`btn ${hasCodes ? "btn-success" : "btn-secondary"} mt-1`} type="button"
                        onClick={() => setShow(true)}>
                        <i className="fa-solid fa-diagram-next me-2" style={{ fontSize: '16px' }}></i>
                        Configurar códigos
                    </button>
                </div>
            </div>

            <ModalUtilitiesCodeTypePeople show={show} setShow={setShow} />
        </>
    );
}

export default TabCodesUtilitiesTypePeople;