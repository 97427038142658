import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import CardSwitch from "../../../../app/components/CardSwitch";
import CardConfigActor from "./CardConfigActor";

import GeneralService from "../../../../services/GeneralService";
import { changeZapsignOptionPeople, reducerFormSelectedPerson, setSelectedPerson, updateOnePersonFromPeople, updateSelectedActors } from "../../../../reducers/documents/reducerGenerateDocument";
import { toast } from "../../../../utils/toast";
import { TOAST_DANGER, ID_NOT_DEFINED } from "../../../../constant/constant";
import { MessageError } from "../../../../utils/message";
import { setApplyDefault, setList } from "../../../../reducers/configuration/reducerZapsignConfiguration";
import { startModifyNeedsZapsign } from "../../../../reducers/documents/thunks";

const TabSending = ({ canEdit = true, setCurrentStep }) => {
    const { selectedPeople, selectedPerson, generateDocument } = useSelector(state => state.documentsAutoSlice);
    const { applyDefault, list } = useSelector(state => state.zapsignConfigurationSlice);
    const dispatch = useDispatch();
    const refForm = useRef(null);

    const setData = (e) => {
        dispatch(reducerFormSelectedPerson({
            key: e.target.name,
            value: e.target.value,
        }));
    }

    const loadZapsignConfiguration = async () => {
        if (generateDocument.needs_zapsign) {
            const service = new GeneralService("/zapsign-configuration");
            const res = await service.getList();
            dispatch(setList({ value: res.configurations }));
        }
    }

    const setDataSwitch = (e) => {
        dispatch(reducerFormSelectedPerson({
            key: e.target.name,
            value: e.target.checked,
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!canEdit) {
            toast(dispatch, "Ya no puedes editar estos parámetros", TOAST_DANGER);
            return;
        }

        if (selectedPerson.id !== ID_NOT_DEFINED) {
            const service = new GeneralService("actors-generate-document");
            const res = await service.update(selectedPerson);
            if (!res.is_ok) {
                MessageError(res.message, service.status);
                return;
            }
        }
        proccessUpdate();
    }

    const proccessUpdate = () => {
        const actorsData = selectedPeople.map(item => {
            if (item.people_id === selectedPerson.people_id) return selectedPerson;
            return item;
        })
        dispatch(updateOnePersonFromPeople({ value: actorsData }));
        toast(dispatch, "Cambios guardados en el actor");
    }

    const setZapsignConfiguration = () => {
        if (applyDefault) {
            dispatch(changeZapsignOptionPeople({ value: list }));
        }
    }

    const handleNeedsZapsign = async () => {
        dispatch(startModifyNeedsZapsign(generateDocument.id, !generateDocument.needs_zapsign));
    }

    const settingsZapsign = (<div className="row">
        <div className="col">
            <p>Formas de verificación</p>
            <CardSwitch
                icon="fa-solid fa-camera text-green"
                name="require_selfie_photo"
                title="Selfie"
                checked={selectedPerson.require_selfie_photo}
                disabled={!canEdit}
                onChange={setDataSwitch}
            />
            <CardSwitch
                icon="fa-solid fa-address-card text-green"
                name="require_document_photo"
                title="Documento de identidad"
                checked={selectedPerson.require_document_photo}
                disabled={!canEdit}
                onChange={setDataSwitch}
            />
        </div>
        <div className="col">
            <p>Envío automático de firma a:</p>
            <CardSwitch
                icon="fa-brands fa-square-whatsapp text-green"
                name="automatic_whatsapp"
                title="WhatsApp"
                checked={selectedPerson.automatic_whatsapp}
                disabled={!canEdit}
                onChange={setDataSwitch}
            />
            <CardSwitch
                icon="fa-solid fa-envelope text-green"
                name="automatic_mailing"
                title="Correo electrónico"
                checked={selectedPerson.automatic_mailing}
                disabled={!canEdit}
                onChange={setDataSwitch}
            />
        </div>
    </div>);

    useEffect(() => {
        if (selectedPeople.length > 0) {
            dispatch(setSelectedPerson({ value: selectedPeople[0] }));
        }
    }, []);

    useEffect(() => {
        loadZapsignConfiguration();
    }, [generateDocument.needs_zapsign]);

    useEffect(() => {
        setZapsignConfiguration();
    }, [applyDefault, list]);

    return (
        <div className="process-tab">
            <div className="row">
                <div className="col-5 border-right-dashed pe-4">
                    <h5><b>Gestiona las firmas</b></h5>
                    <p>A continuación puedes ver los actores que seleccionaste en el paso anterior, y puedes elegir si requiere firma en ZapSign, esto aplica para todos los actores.</p>

                    <CardSwitch
                        name="needs_zapsign"
                        icon="fa-solid fa-signature"
                        title="¿Necesitas firma electrónica?"
                        checked={generateDocument.needs_zapsign}
                        disabled={!canEdit}
                        onChange={handleNeedsZapsign}
                    />

                    <div className="p-2"></div>

                    <div className="row" style={{ height: "20rem", overflowY: "auto" }}>
                        <div className="col">
                            {
                                selectedPeople.map(person =>
                                    <CardConfigActor
                                        key={person.people_id}
                                        person={person}
                                        active={selectedPerson.people_id === person.people_id}
                                        canEdit={canEdit}
                                        refForm={refForm}
                                    />)
                            }
                        </div>
                    </div>

                </div>

                <div className="col ps-3">
                    {
                        Object.keys(selectedPerson).length > 0 &&
                        <form ref={refForm} onSubmit={handleSubmit}>
                            {
                                generateDocument.needs_zapsign && <>
                                    <div className="d-block rounded-3 animate__animated animate__fadeInDown">
                                        <div className="row mb-3">
                                            <div className="col-4">
                                                <h5 className="font-lato fw-bold">
                                                    <i className="fa-solid fa-signature me-2"></i>
                                                    Firma electrónica
                                                </h5>
                                            </div>
                                            {
                                                canEdit
                                                &&
                                                <div className="col">
                                                    <CardSwitch
                                                        name="apply_default"
                                                        icon="fa-solid fa-sliders"
                                                        title="¿Deseas aplicar la configuración por defecto?"
                                                        checked={applyDefault}
                                                        onChange={() => dispatch(setApplyDefault({ value: !applyDefault }))}
                                                    />
                                                </div>
                                            }

                                        </div>

                                        {!canEdit && settingsZapsign}
                                        {(canEdit && !applyDefault) && settingsZapsign}

                                    </div>
                                    <hr className="mt-4 text-green" />
                                </>
                            }

                            <h5 className="font-lato fw-bold pb-2">
                                <i className="fa-regular fa-address-book me-2"></i>
                                Datos de contacto
                            </h5>

                            <div className="row mt-2">
                                <div className="col-2">
                                    Nombre<b><i className="text-red">*</i></b>
                                </div>
                                <div className="col-7">
                                    <input
                                        type="text"
                                        className="form-control green-input"
                                        name="name"
                                        placeholder="Digita nombre(s)"
                                        value={selectedPerson.name}
                                        minLength="3"
                                        maxLength="25"
                                        onChange={setData}
                                        readOnly={!canEdit}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-2">
                                    WhatsApp<b><i className="text-red">*</i></b>
                                </div>
                                <div className="col-7">
                                    <input
                                        type="text"
                                        className="form-control green-input"
                                        name="phone"
                                        placeholder="Digita número telefónico"
                                        value={selectedPerson.phone}
                                        minLength="3"
                                        maxLength="25"
                                        onChange={setData}
                                        readOnly={!canEdit}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-2">
                                    Correo<b><i className="text-red">*</i></b>
                                </div>
                                <div className="col-7">
                                    <input
                                        type="text"
                                        className="form-control green-input"
                                        name="email"
                                        placeholder="Digita correo electrónico"
                                        value={selectedPerson.email}
                                        minLength="3"
                                        maxLength="100"
                                        onChange={setData}
                                        readOnly={!canEdit}
                                        required
                                    />
                                </div>
                            </div>
                        </form>
                    }
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <button
                        type="button"
                        className="btn btn-success rounded-3 d-block ms-auto mt-4 px-3 py-2"
                        onClick={() => {
                            if (refForm.current.checkValidity()) setCurrentStep(c => c + 1);
                            else refForm.current.reportValidity();
                        }}
                    >
                        Siguiente paso <i className="fa-solid fa-caret-right"></i>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default TabSending;