import { useState, useEffect } from 'react';

export const NetworkStatus = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    return (
        !isOnline
        &&
        <div className="network-status toast-danger" role="alert">
            <i className="fas fa-triangle-exclamation d-inline-block me-2 animate__animated animate__shakeX animate__infinite"></i>
            Tu conexión a internet es débil
        </div>
    );
}
