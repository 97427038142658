import { useRef, useEffect, useState } from "react";
import { ref, update } from "firebase/database";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "../../../utils/toast";
import { TOAST_DANGER } from "../../../constant/constant";
import { FirebaseDB } from "../../../firebase/config/firebase-config";

const MessageItem = ({
    avatar, showAvatar = false, name, showName = false, options, message,
    isExternal = false, onClick = (e) => { }, isLarge = true
}) => {
    const [contextMenuVisible, setContextMenuVisible] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });

    const cardRef = useRef(null);

    useEffect(() => {
        document.addEventListener("click", handleDocumentClick);
        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, []);

    const handleContextMenu = (e) => {
        e.preventDefault();
        setContextMenuPosition({ x: e.clientX, y: e.clientY });
        setContextMenuVisible(c => !c);
    };

    const handleHideContextMenu = () => {
        setContextMenuVisible(false);
    };

    const handleDocumentClick = (e) => {
        if (cardRef.current && !cardRef.current.contains(e.target)) {
            setContextMenuVisible(false);
        }
    };

    const handleClick = (e) => {
        onClick(e);
        if (contextMenuVisible) {
            setContextMenuVisible(c => !c);
        }
    }

    return (
        <div
            ref={cardRef}
            onContextMenu={handleContextMenu}
            className={`message ${isExternal ? "" : "left"} mb-2 ${isLarge ? "large" : ""}`}
            onClick={handleClick}
        >
            {
                isExternal
                    ?
                    <>
                        {
                            showAvatar
                            &&
                            <div className="avatar">
                                {avatar}
                            </div>
                        }

                        <div className="body">
                            {
                                showName && <div className="row">
                                    <div className="col">
                                        <span className="font-lato">{name}</span>
                                    </div>
                                </div>
                            }

                            <div className="text">
                                <div dangerouslySetInnerHTML={{ __html: message.content }}></div>
                            </div>
                            {options}
                        </div>
                    </>
                    :
                    <>
                        <div className="body">
                            {
                                showName && <div className="row">
                                    <div className="col">
                                        <span className="font-lato">{name}</span>
                                    </div>
                                </div>
                            }

                            <div className="text">
                                {message.content}
                            </div>
                            {options}
                        </div>
                        {
                            showAvatar
                            &&
                            <div className="avatar">
                                {avatar}
                            </div>
                        }
                    </>
            }
            <ContextMenu
                visible={contextMenuVisible}
                x={contextMenuPosition.x}
                y={contextMenuPosition.y}
                onHide={handleHideContextMenu}
                message={message}
            />
        </div>
    )
}

const ContextMenu = ({ visible, message, x, y, onHide }) => {
    const { selectedChat } = useSelector(state => state.businessChatSlice);
    const { nick_name } = useSelector(state => state.loginSlice.profile);
    const { company } = useSelector(state => state.businessSlice);
    const dispatch = useDispatch();

    if (!visible) return null;

    const style = {
        top: y,
        left: x,
    };

    const handleCopyContent = () => {
        navigator.clipboard.writeText(message.content);
    }

    const handleDeleteMessage = () => {
        const messageRef = ref(FirebaseDB, `businesses/business_id_${company.id}/chats/${selectedChat.chatId}/messages/${message.id}/deleteFor`);

        update(messageRef, {
            [nick_name]: true
        })
            .then(() => toast(dispatch, "Mensaje eliminado"))
            .catch(() => toast(dispatch, "Mensaje eliminado", TOAST_DANGER));
    };

    return (
        <div className="context-menu__chat fs-6" style={style}>
            <div role="button" onClick={handleDeleteMessage}>
                <i className="fa-solid fa-trash-can me-2"></i>Eliminar
            </div>
            <div role="button" onClick={handleCopyContent}>
                <i className="fa-solid fa-copy me-2"></i>Copiar
            </div>
        </div>
    );
};

export default MessageItem;