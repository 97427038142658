import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import service from "../../UtilitiesCodeTypePeople/service";
import { cutName } from "../../DocumentsAuto/utils/functions";
import { MessageError } from "../../../utils/message";
import { OverlayTrigger, Popover } from "react-bootstrap";
import ItemUtilityCodeCategory from "./ItemUtilityCodeCategory";
import { TOAST_SUCCESS, ID_NOT_DEFINED } from "../../../constant/constant";
import { deleteFromCodes, insertFromCodes, updateFromCodes } from "../../../reducers/configuration/utilitiesCodeTypePeopleSlice";
import { hideToast, showToast } from "../../../reducers/main/toastReducer";

const CardUtilityCodeTypePeople = ({ field }) => {
    const { dataTypePeole } = useSelector(state => state.peopleSlice);
    const selected = field.state;
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();

    const toast = (message) => {
        dispatch(showToast({
            type: TOAST_SUCCESS,
            message,
        }));
        setTimeout(() => {
            dispatch(hideToast());
        }, 3000);
    }

    const mark = async (name) => {
        let data = {
            id: field.utilities_id,
            name,
            type_people_id: parseInt(dataTypePeole.type_people_id),
        };

        if (field.category_peoples_id) data["parameterization_category_peoples_id"] = field.id;
        else data["parameterization_type_peoples_id"] = field.id;

        if (selected) {
            const res = await service.update(data);
            setShow(false);
            if (res.is_ok) {
                toast(res.message);
                dispatch(updateFromCodes({
                    value: {
                        id: field.id, is_category: !!field.category_peoples_id, type_category: name,
                    }
                }));
                return;
            }
            MessageError(res.message, service.status);
            return;
        }

        const res = await service.save(data);
        setShow(false);
        if (res.is_ok) {
            toast(res.message);
            dispatch(insertFromCodes({
                value: {
                    id: field.id, is_category: !!field.category_peoples_id, type_category: name, utilities_id: res.utilities_code_type_people.id,
                }
            }));
            return;
        }
        MessageError(res.message, service.status);
    }

    const deleteUtility = async () => {
        const res = await service.delete(field.utilities_id);
        if (res.is_ok) {
            dispatch(deleteFromCodes({
                value: { id: field.id, is_category: !!field.category_peoples_id },
            }));
            toast(res.message);
            return;
        }
        MessageError(res.message, service.status);
    }

    const popover = (
        <Popover id="popover-basic">
            <Popover.Header className="bg-purple text-white text-center" as="h3">Campo</Popover.Header>
            <Popover.Body>
                <ItemUtilityCodeCategory
                    name="NOMBRE"
                    active={field.type_category === "FULLNAME"}
                    onClick={() => mark("FULLNAME")}
                />
                <ItemUtilityCodeCategory
                    className="mt-1"
                    name="MAIL"
                    active={field.type_category === "MAIL"}
                    onClick={() => mark("MAIL")}
                />
                <ItemUtilityCodeCategory
                    className="mt-1"
                    name="WHATSAPP"
                    active={field.type_category === "WHATSAPP"}
                    onClick={() => mark("WHATSAPP")}
                />

                {
                    field.utilities_id !== ID_NOT_DEFINED &&
                    <>
                        <hr />

                        <div className="row mt-1">
                            <div className="col text-center category-code-delete__config" role="button" onClick={deleteUtility}>
                                Borrar
                            </div>
                        </div>
                    </>
                }
            </Popover.Body>
        </Popover>
    );

    return (
        <div
            className="col m-2 p-2 card-favorite"
            title={field.name}>
            {
                field.category_peoples_id &&
                <div className="row" style={{ backgroundColor: "orange", padding: "1px", margin: "5px" }}>
                </div>
            }
            <div className={`row ${!field.category_peoples_id && "mb-2"}`}>
                <div className="col-1"></div>
                <div className="col-10">
                    <div className="row text-capitalize fw-bold fs-7">
                        {cutName(field?.name, 20)}
                    </div>
                    <div className="row">
                        <span className="tiny ps-0 pe-1 text-capitalize">{field.cluster}</span>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <OverlayTrigger placement="bottom" overlay={popover} show={show}>
                        <div className={`d-block rounded-2 pb-1 text-center ${field.state ? "bg-light-purple text-purple" : "bg-light-gray"}`} role="button"
                            onClick={() => setShow(c => !c)}
                        >
                            <span className="fw-bold fs-7">{field.type_category === "FULLNAME" ? "NOMBRE": field.type_category}</span>
                        </div>
                    </OverlayTrigger>
                </div>
            </div>
        </div>
    );
}

export default CardUtilityCodeTypePeople;