import { useDispatch, useSelector } from 'react-redux';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import IconButton from '../../../app/components/IconButton';
import CardActorViewSignature from '../../TercerosCreate/components/CardActorViewSignature';

import { formatDateTime } from '../../../utils/formatDate';
import { useState } from 'react';
import { isURL } from '../../../utils/isUrl';
import { toast } from '../../../utils/toast';
import { TOAST_WARNING } from '../../../constant/constant';
import { useDownloadFile } from '../../../app/hooks/useDownloadFile';

const ListProcess = () => {
    const { elatedDocumentsFiltered } = useSelector(state => state.virtualNotaryProcessSlice);

    return (
        <>
            <div className="row">
                <div className="d-block rounded-3 px-0">
                    <table className={`table table-responsive table-header-custom animate__animated animate__fadeIn ${elatedDocumentsFiltered.length > 0 && "table-striped"}`}>
                        <thead>
                            <tr>
                                <th></th>
                                <th>#</th>
                                <th>Tipo de formulario</th>
                                <th>Fecha</th>
                                <th>Estado</th>
                                <th>Acciones</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                elatedDocumentsFiltered.length === 0
                                    ?
                                    <tr className="p-2 text-center bg-light-green" >
                                        <td colSpan={7}>No hay grupos que mostrar</td>
                                    </tr>
                                    :
                                    elatedDocumentsFiltered.map((process, i) => (<RowProcess
                                        key={process.id}
                                        process={process}
                                        index={i}
                                    />))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

const RowProcess = ({ process, index }) => {    
    const handleDownload = useDownloadFile();
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();

    const signedFile = () => {
        if (isURL(process.url_signed_file)) {
            window.open(process.url_signed_file);
            return;
        }
        toast(dispatch, "Este documento no ha sido firmado", TOAST_WARNING)
    }

    const settingStatus = (proc) => {
        const signers = proc.actors?.length;
        let counter = 0;
        proc.actors?.forEach(actor => {
            if (actor.status === "signed") counter++;
        });

        const signed = proc.status === "signed";
        return signed
            ?
            <><i role="button" title="Firmado" className="fa-solid fa-file-circle-check text-success"></i> {counter}/{signers}</>
            :
            <><i role="button" title="No firmado" className="fa-solid fa-file-circle-xmark text-orange"></i> {counter}/{signers}</>;
    }

    const popover = (
        <Popover style={{ minWidth: "25rem", maxHeight: "25rem", overflow: "scroll", overflowX: "hidden" }}>
            <Popover.Header className="bg-purple text-white text-center" as="h3">Actores del documento</Popover.Header>
            <Popover.Body>
                <div className="container">
                    {
                        process.actors?.map(item => <CardActorViewSignature
                            key={item.id}
                            actor={item}
                            label={item.actor?.name}
                        />)
                    }
                </div>
            </Popover.Body>
        </Popover>
    );

    return (
        <tr>
            <td></td>
            <td style={{width: "40px"}}>{index + 1}.</td>
            <td style={{ maxWidth: "300px" }}>{process.form.name}</td>
            <td>{formatDateTime(process.date_sent)}</td>
            <td style={{ maxWidth: "400px" }}>
                {settingStatus(process)}
            </td>
            <td>
                <OverlayTrigger placement="left" overlay={popover} show={show}>
                    <button title="Ver actores del documento" className='bg-icon_button' onClick={() => setShow(!show)} style={{ border: "none" }}>
                        <i className="fa-solid fa-person-rays text-purple"></i>
                    </button>
                </OverlayTrigger>
                <IconButton
                    icon="fa-solid fa-file-arrow-down text-green"
                    title="Descargar documento original"
                    onClick={() => handleDownload(process.url_pdf)}
                />
                <IconButton
                    icon="fa-solid fa-file-contract text-purple"
                    title="Descargar documento firmado"
                    onClick={signedFile}
                />
            </td>
            <td></td>
        </tr>
    )
}

export default ListProcess;