import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import queryString from 'query-string';

import GeneralService from "../../../services/GeneralService";
import { TOAST_DANGER, TOAST_SUCCESS, HIDE, SHOW } from "../../../constant/constant";

export const VerifyEmail = () => {
    const history = useHistory();
    const { search } = useLocation();
    const { uuid } = queryString.parse(search);
    const [showVerificationMessage, setShowVerificationMessage] = useState({ is_ok: false, show: HIDE, message: "", verified: false });
    const [verified, setVerified] = useState(false);

    const handleTokenVerification = async () => {
        const path = history.location.pathname;
        const hash = history.location.hash;
        history.push(path + hash);

        if (uuid && !verified) {
            const service = new GeneralService("user/verify-email");
            const res = await service.post({ uuid });
            setShowVerificationMessage({ ...showVerificationMessage, ...res, show: SHOW });
        }
        setVerified(true);
    }

    useEffect(() => {
        handleTokenVerification();
    }, [uuid]);

    return showVerificationMessage.show
        &&
        <div className="row">
            <div className="col">
                <div className={`d-block alert px-2 py-1 ${showVerificationMessage.is_ok ? TOAST_SUCCESS : TOAST_DANGER}`}>

                    <i className={`fa-solid ${showVerificationMessage.is_ok ? "fa-circle-check" : "fa-circle-xmark"} me-2`}></i>
                    {showVerificationMessage.message}
                </div>
            </div>
        </div>
}
