import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import Select from "react-select";

import GeneralService from "../../../services/GeneralService";
import { MessageError, toast } from "../../../utils";
import { setEvent, setCleanEvent } from "../../../reducers/calendar/reducerCalendar";
import { downloadImageAsBase64 } from "../../../firebase/firebaseStorage";
import { reactSelectStyles } from "../../Expedients/generate/styles/reactSelectStyles";
import { TOAST_DANGER, TOAST_SUCCESS, HIDE, ID_NOT_DEFINED } from "../../../constant/constant";

export const ModalEvent = ({ show, setShow, getEvents, selectedDate }) => {
  const { labels, user, event } = useSelector(state => state.calendarslice);
  const [options, setOptions] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const dispatch = useDispatch();

  const formatOptionLabel = ({ avatar, label, email }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img
        src={avatar}
        alt={label}
        style={{ width: 30, height: 30, borderRadius: "50%", marginRight: 10 }}
      />
      <div>
        <div>{label}</div>
        <div style={{ fontSize: "12px", color: "#888" }}>{email}</div>
      </div>
    </div>
  )

  const handleLabelClick = (e, labelid) => {
    e.preventDefault();
    setSelectedLabel(labelid);

    dispatch(setEvent({ key: "labels_id", value: [labelid] }));
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    dispatch(setEvent({ key: name, value }));
  }

  const handleSelectChange = (selectedOption, actionMeta) => {
    dispatch(setEvent({ key: actionMeta.name, value: selectedOption.value }));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(new Date(event.start_date) > new Date(event.end_date)) {
      return toast(dispatch, "Fecha inicial mayor a la final",  TOAST_DANGER);
    }

    const updatedEvent = {
      ...event,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    const service = new GeneralService("events");

    if (event.id === ID_NOT_DEFINED) {
      const res = await service.post(updatedEvent);
      if (!res.is_ok) {
        return MessageError(res.message);
      }
    } else {
      const res = await service.update({ Event: updatedEvent, id: updatedEvent.id });
      if (!res.is_ok) {
        return MessageError(res.message);
      }
    }

    toast(dispatch, "Proceso exitoso", TOAST_SUCCESS, 5000);
    setCleanEvent();
    getEvents();
    setShow(HIDE);
  }

  const loadOptions = async () => {
    const options = [];
    for (const item of user) {
      const base64 = await downloadImageAsBase64(item.url_avatar);

      options.push({
        value: item.id,
        label: item.name,
        avatar: base64,
        email: item.user.email,
      });
    }
    setOptions(options);
  }

  useEffect(() => {
    loadOptions();
  }, [user]);

  useEffect(() => {
    if (
      event && event.id !== ID_NOT_DEFINED &&
      event.labels_id &&
      event.labels_id.length > 0
    ) {
      setSelectedLabel(event.labels_id[0]);
    }
  }, [event]);

  return (
    <Modal show={show} onHide={() => setShow(HIDE)} size='lg'>
      <Modal.Header className='bg-green pe-4' closeButton>
        {
          event.id === ID_NOT_DEFINED
            ?
            <i className="fa-solid fa-circle-plus animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
            :
            <i className="fa-solid fa-pen-to-square animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>

        }
        <h5 className="mt-2 ms-2">{event.id === ID_NOT_DEFINED ? "Crear evento" : "Editar evento"}</h5>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="containermodal2">
            <div className="labelsmodal2">
              <div className="container-parent2">
                {labels.map((item, index) => (
                  <button
                    id={item.id}
                    key={index}
                    className={`btndrop ${selectedLabel === item.id ? "selected" : ""}`}
                    style={{
                      color: item.label_color.color_font,
                      backgroundColor: item.label_color.color,
                      marginLeft: "5px",
                      border: selectedLabel === item.id ? "1px solid black" : "none",
                    }}
                    onClick={(e) => handleLabelClick(e, item.id)}
                  >
                    {item.name}
                  </button>
                ))}
              </div>
              <div className="divselecthour">
                <select
                  name="notification_time"
                  className="btn dropdown-toggle green-input"
                  value={event.notification_time}
                  onChange={handleChange}
                  required
                >
                  <option selected className="dropdown-item" value="">
                    Notificación
                  </option>
                  <option className="dropdown-item" value="15M">
                    15M
                  </option>
                  <option className="dropdown-item" value="30M">
                    30M
                  </option>
                  <option className="dropdown-item" value="1H">
                    1H
                  </option>
                  <option className="dropdown-item" value="5H">
                    5H
                  </option>
                  <option className="dropdown-item" value="1D">
                    1D
                  </option>
                  <option className="dropdown-item" value="1W">
                    1W
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div className="divmodal2body mt-2">
            <div className="divinput">
              <i className="fa-solid fa-file-pen text-green" />
              <input
                className="form-control green-input"
                type="text"
                placeholder="Titulo del evento"
                name="title"
                value={event.title}
                onChange={handleChange}
                required
                autoComplete="off"
              />
            </div>

            <div className="row mt-2">
              <div className="col-1" style={{ width: "26px" }}>
                <i className="fa-solid fa-user-plus text-green" />
              </div>
              <div className="col pe-0">
                <Select
                  value={options.find(option => option.value === event.responsible_id)}
                  onChange={handleSelectChange}
                  options={options}
                  formatOptionLabel={formatOptionLabel}
                  name="responsible_id"
                  placeholder="Responsable del evento"
                  styles={reactSelectStyles}
                />
              </div>
              <div className="col">
                <Select
                  value={options.find(option => option.value === event.reporting_id)}
                  onChange={handleSelectChange}
                  options={options}
                  formatOptionLabel={formatOptionLabel}
                  name="reporting_id"
                  placeholder="Informador del evento"
                  styles={reactSelectStyles}
                />
              </div>
            </div>

            <div className="row mt-2">
              <div className="col">
                <div className="divinput">
                  <i className="fa-solid fa-clock text-green"></i>
                  <input
                    className="form-control green-input"
                    type="datetime-local"
                    name="start_date"
                    value={event.start_date}
                    onChange={handleChange}
                    required
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="col">
                <div className="divinput">
                  <i className="fa-solid fa-clock text-green"></i>
                  <input
                    className="form-control green-input"
                    type="datetime-local"
                    name="end_date"
                    value={event.end_date}
                    onChange={handleChange}
                    required
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>

            <div className="divinput mt-2">
              <i className="fa-solid fa-align-justify text-green"></i>
              <textarea
                className="form-control textarea-style green-input"
                placeholder="Agrega una descripción..."
                name="description"
                value={event.description}
                onChange={handleChange}
                required
                autoComplete="off"
              />
            </div>
          </div>
          <div className="divbotones">
            <button type="submit" title="Agregar"
              className="btn btn-success"
            >
              <i className="fa-solid fa-paper-plane text-white me-2" />
              Guardar
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
