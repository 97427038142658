import { useSelector } from "react-redux";

const typeAlert = {
    "toast-success": "fa-check",
    "toast-warning": "fa-triangle-exclamation",
    "toast-danger": "fa-times",
    "toast-info": "fa-exclamation",
}

const ToastNotify = () => {
    const { dataToast } = useSelector(state => state.toastSlice);

    return (
        <div className={`toast-custom ${dataToast.type}`} role="alert">
            <i className={`fas ${typeAlert[dataToast.type]} d-inline-block me-2 animate__animated animate__pulse animate__infinite`}></i>
            {dataToast.message}
        </div >
    );
}

export default ToastNotify;