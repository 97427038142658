import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CardSwitch from "../../../app/components/CardSwitch";

import GeneralService from "../../../services/GeneralService";
import { reducerForm, setSelected } from "../../../reducers/configuration/reducerConfigCronjobExpedient";
import { TOAST_INFO, HTTP_OK } from "../../../constant/constant";
import { MessageError, toast } from "../../../utils";

export const NotificationExpedient = () => {
    const { selectedConfig } = useSelector(state => state.configCronjobExpedientSlice);
    const [countChanges, setCountChanges] = useState(0);
    const dispatch = useDispatch();
    const service = new GeneralService("config-cronjob-expedient");

    const loadConfig = async () => {
        const res = await service.getRequest();
        if (service.status === HTTP_OK) {
            dispatch(setSelected(res));
            return;
        }
        toast(dispatch, "No has configurado las notificaciones", TOAST_INFO);
    }

    useEffect(() => {
        loadConfig();
    }, []);

    const setData = async (e) => {
        dispatch(reducerForm({
            key: e.target.name,
            value: e.target.value,
        }));
        setCountChanges(countChanges + 1);
    }

    const setDataSwitch = async (e) => {
        dispatch(reducerForm({
            key: e.target.name,
            value: e.target.checked,
        }));
        setCountChanges(countChanges + 1);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if (countChanges === 0) return;

        const data = {
            id: selectedConfig.id,
            days: parseInt(selectedConfig.days),
            hour: parseInt(selectedConfig.hour),
            enable: selectedConfig.enable,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };

        const res = await service.post(data);
        if (res.is_ok) {
            toast(dispatch, res.message);
            dispatch(setSelected(res.config));
            setCountChanges(0); // Reset count
            return;
        }
        MessageError(res.message, service.status);
    }

    return (
        <div className={`col px-3 py-4 animate__animated animate__fadeIn`}>

            <div className="row center-vertical">
                <div className="col">
                    <h5 className="font-lato">
                        <i className="fa-solid fa-stopwatch me-2"></i>
                        Notificaciones expedientes
                    </h5>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col">
                    <p>Configura la frecuencia con que deseas recibir notificaciones de los movimientos de tus expedientes próximos a vencer. Recuerda que puedes activarlas y desactivarlas.</p>
                </div>
            </div>

            <form onSubmit={onSubmit}>
                <div className="row">
                    <div className="col-4">
                        <label className="fs-7">¿Cada cuánto ejecutar la verificación? (días)</label>
                        <input
                            type="number"
                            name="days"
                            className="form-control green-input"
                            value={selectedConfig.days}
                            min={1}
                            onChange={setData}
                            required
                        />
                    </div>

                    <div className="col-3">
                        <label className="fs-7">¿A qué hora ejecutar?</label>
                        <select
                            className="form-select green-input"
                            name="hour"
                            value={selectedConfig.hour}
                            onChange={setData}
                            required
                        >
                            <option value="">Seleccione...</option>
                            <option value={6}>06 AM</option>
                            <option value={7}>07 AM</option>
                            <option value={8}>08 AM</option>
                            <option value={9}>09 AM</option>
                            <option value={10}>10 AM</option>
                            <option value={11}>11 AM</option>
                            <option value={12}>12 PM</option>
                            <option value={13}>01 PM</option>
                            <option value={14}>02 PM</option>
                            <option value={15}>03 PM</option>
                            <option value={16}>04 PM</option>
                            <option value={17}>05 PM</option>
                            <option value={18}>06 PM</option>
                        </select>
                    </div>

                    <div className="col-3">
                        <label className="fs-7">Habilitar notificaciones</label>
                        <CardSwitch
                            icon={`fa-solid ${selectedConfig.enable ? "fa-bell" : "fa-bell-slash"} text-purple`}
                            className="mt-2"
                            name="enable"
                            title="Habilitar"
                            checked={selectedConfig.enable}
                            onChange={setDataSwitch}
                        />
                    </div>

                    <div className="col-2">
                        <button type="submit" title="Guardar cambios"
                            className={`btn ${countChanges > 0 ? "btn-success" : "btn-secondary"} mt-4 d-block`}
                        >
                            <i className="fa-solid fa-floppy-disk me-2"></i>
                            Guardar
                        </button>
                    </div>
                </div>
            </form>
        </div >
    );
}