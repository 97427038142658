import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import IconButton from '../../../app/components/IconButton';
import CardActorViewSignature from './CardActorViewSignature';

import { formatDateTime } from '../../../utils/formatDate';
import { isURL } from '../../../utils/isUrl';
import { toast } from '../../../utils/toast';
import { TOAST_WARNING, HIDE } from '../../../constant/constant';
import { SignatureLegend } from './SignatureLegend';
import { useDownloadFile } from '../../../app/hooks/useDownloadFile';
import GeneralService from '../../../services/GeneralService';
import { convertBase64ToArrayBuffer, downloadFileFromArrayBuffer } from '../../../utils';

const ListGeneratedDocuments = ({ isPerPerson = false }) => {
    const { elatedDocumentsFiltered } = useSelector(state => state.personDocumentSlice);

    return (
        <>
            <div className="row">
                {
                    elatedDocumentsFiltered.length === 0
                        ?
                        <div className="alert alert-warning text-center mt-2" role="alert">
                            No hay documentos que mostrar
                        </div>
                        :
                        <div className="d-block rounded-3 clip-hide">
                            <table className={`table table-responsive table-header-custom animate__animated animate__fadeIn ${elatedDocumentsFiltered.length > 0 && "table-striped"}`}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>#</th>
                                        <th>Tipo de documento</th>
                                        <th>Fecha</th>
                                        <th>Estado</th>
                                        <th>Acciones</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        elatedDocumentsFiltered.map((document, i) => (<RowDocument
                                            key={document.id}
                                            generatedDocument={document}
                                            index={i}
                                            isPerPerson={isPerPerson}
                                        />))
                                    }
                                </tbody>
                            </table>
                            <SignatureLegend />
                        </div>
                }
            </div>
        </>
    )
}

const RowDocument = ({ generatedDocument, index, isPerPerson = false }) => {
    const { peopleId } = useSelector(state => state.peopleSlice);
    const [show, setShow] = useState(false);
    const [showPopoverExport, setShowPopoverExport] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const handleDownload = useDownloadFile();
    const rowRef = useRef(null);

    const signedFile = () => {
        if (isURL(generatedDocument.url_signed_file)) {
            window.open(generatedDocument.url_signed_file);
            return;
        }
        toast(dispatch, "Este documento no ha sido firmado por nadie", TOAST_WARNING)
    }

    const settingStatus = (doc) => {
        const signers = doc.actors?.length;
        let counter = 0;
        doc.actors?.forEach(actor => {
            if (actor.status === "signed") counter++;
        });

        const signed = isPerPerson ? doc.actors.find(actor => actor.people_id === peopleId)?.status === "signed" : doc.status === "signed";
        return signed
            ?
            <><i role="button" title="Firmado" className="fa-solid fa-file-circle-check text-success"></i> {counter}/{signers}</>
            :
            <><i role="button" title="No firmado" className="fa-solid fa-file-circle-xmark text-orange"></i> {counter}/{signers}</>;
    }

    const handleExportExcel = async () => {
        setShowPopoverExport(HIDE);
        const service = new GeneralService("document-report/" + generatedDocument.id);
        const res = await service.getRequest();
        if (res.is_ok) {
            const arrayBuffer = convertBase64ToArrayBuffer(res.file);
            downloadFileFromArrayBuffer(arrayBuffer, "SortomExport.xlsx");
        }
    }

    const downloadDocument = () => {
        setShowPopoverExport(HIDE);
        if (isURL(generatedDocument.url_pdf)) {
            handleDownload(generatedDocument.url_pdf);
            return;
        }
        toast(dispatch, "El documento no ha sido generado", TOAST_WARNING);
    }

    const popover = (
        <Popover style={{ minWidth: "25rem", maxHeight: "25rem", overflowY: "auto", overflowX: "hidden" }}>
            <Popover.Header className="bg-purple text-white text-center" as="h3">Actores del documento</Popover.Header>
            <Popover.Body>
                <div className="container">
                    {
                        generatedDocument.actors?.map(actor => <CardActorViewSignature
                            key={actor.id}
                            actor={actor}
                            label={actor.label?.name}
                        />)
                    }
                </div>
            </Popover.Body>
        </Popover>
    );

    const popoverExport = (
        <Popover style={{ minWidth: "10rem" }}>
            <Popover.Header className="bg-green text-center" as="h3">Exportar documento</Popover.Header>
            <Popover.Body className='d-flex justify-content-center'>
                <button title="Exportar en formato excel" className='btn btn-success' onClick={handleExportExcel}>
                    <i className="fa-solid fa-file-excel"></i>
                </button>
                <button title="Descargar PDF" className='btn btn-success ms-2' onClick={downloadDocument}>
                    <i className="fa-solid fa-file-pdf"></i>
                </button>
                <button title="Descargar documento firmado" className='btn btn-success ms-2' onClick={signedFile}>
                    <i className="fa-solid fa-file-contract"></i>
                </button>
            </Popover.Body>
        </Popover>
    );

    const handleRowClick = (e) => {
        if (rowRef.current && !rowRef.current.contains(e.target)) {
            setShow(false);
        }
    };

    const handleEditDocument = () => {
        history.push("/documents-auto/generate/" + generatedDocument.documents_auto_id + "?process=" + generatedDocument.id);
    }

    useEffect(() => {
        document.addEventListener("click", handleRowClick);
        return () => {
            document.removeEventListener("click", handleRowClick);
        };
    }, []);

    return (
        <tr ref={rowRef}>
            <td></td>
            <td style={{width: "40px"}}>{index + 1}.</td>
            <td style={{ maxWidth: "300px" }}>{generatedDocument.documents_auto.name}</td>
            <td>{formatDateTime(generatedDocument.date_sent)}</td>
            <td style={{ maxWidth: "400px" }}>
                {settingStatus(generatedDocument)}
            </td>
            <td>
                <OverlayTrigger placement="left" overlay={popover} show={show}>
                    <button title="Ver actores del documento" className='bg-icon_button' onClick={() => setShow(!show)} style={{ border: "none" }}>
                        <i className="fa-solid fa-person-rays text-green"></i>
                    </button>
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={popoverExport} show={showPopoverExport}>
                    <button title="Exportar" className='bg-icon_button' onClick={() => setShowPopoverExport(!showPopoverExport)} style={{ border: "none" }}>
                        <i className="fa-solid fa-file-arrow-down text-green"></i>
                    </button>
                </OverlayTrigger>
                <IconButton
                    icon="fa-solid fa-file-pen text-green"
                    title="Continuar editando documento"
                    onClick={handleEditDocument}
                />
            </td>
            <td></td>
        </tr>
    )
}

export default ListGeneratedDocuments;