import TabCodesUtilitiesTypePeople from "./components/ContactData";
import FavoriteCodesCases from "./components/FavoriteCodesCases";
import FavoriteCodesTypePeople from "./components/FavoriteCodesTypePeople";

const TabFavoriteCodes = () => {
    return (
        <div className="animate__animated animate__fadeIn">
            <div className="row center-vertical">
                <div className="col">
                    <h5 className="font-lato">
                        <i className="fa-solid fa-heart me-2"></i>
                        Códigos favoritos
                    </h5>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <FavoriteCodesTypePeople />
                </div>
                {/* <div className="col">
                    <FavoriteCodesCases />
                </div> */}
            </div>

            <hr />
            
            <div className="mt-4"></div>
            <TabCodesUtilitiesTypePeople />
        </div>
    );
}

export default TabFavoriteCodes;