import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DropFiles, CardFileUploaded } from "../../../StorageUser/components";
import { ModalStorageEdit } from "./ModalStorageEdit";

import GeneralService from "../../../../services/GeneralService";
import confirmDelete from "../../../../utils/confirmDelete";
import { MessageError, MessageSuccess, bytesToMegabytes, toast } from "../../../../utils";
import { hideLoading, showLoading } from "../../../../reducers/main/loadingReducer";
import { uploadFilesFirebase } from "../../../../firebase/firebaseStorage";
import { deleteFileFromStorage, setList, setSelectedFile } from "../../../../reducers/expedient/reducerStorageExpedient";

export const ContentStorage = () => {
    const { selectedExpedient } = useSelector(state => state.expedientSlice);
    const { list } = useSelector(state => state.storageExpedientSlice);
    const { company } = useSelector(state => state.businessSlice);
    const [show, setShow] = useState(false);
    const [files, setFiles] = useState([]);
    const dispatch = useDispatch();
    const service = new GeneralService("storage-expedient");

    const deleteItemStorage = async (id) => {
        const res = await service.delete(id);
        if (res.is_ok) {
            toast(dispatch, res.message);
            dispatch(deleteFileFromStorage(id));
            return;
        }
        MessageError(res.message, service.status);
    }

    const handleSubmit = async () => {
        dispatch(showLoading());
        const path = `expedients/business_id_${company.id}/expedient_id_${selectedExpedient.id}`;
        const { is_ok, listUpload, message } = await uploadFilesFirebase(files, path);
        if (is_ok) {
            const res = await uploadFilesBD(listUpload);

            if (res.is_ok) MessageSuccess('¡Los archivos se cargaron correctamente :)!');
            else MessageError("Error inesperado", res.message);

            await loadStorage();
        } else {
            await MessageError(message);
        }

        dispatch(hideLoading());
        setFiles([]);
    }

    const uploadFilesBD = async (files) => {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            const data = {
                url: `https://storage.googleapis.com/${file.metadata.bucket}/${file.metadata.fullPath}`,
                name: file.metadata.name,
                expedients_id: selectedExpedient.id,
                megabytes_used_space: bytesToMegabytes(file.metadata.size),
            }
            const res = await service.post(data);
            if (!res.is_ok) return res;
        }

        return { is_ok: true };
    }

    const loadStorage = async () => {
        const res = await service.getList(1000, { expedient_id: selectedExpedient.id });
        const { storage_expedients = [] } = res;
        let data = storage_expedients.map(item => ({
            ...item,
            path: item.url.replace('https://storage.googleapis.com/npg-storage.appspot.com/', ''),
            type: item.name.split('.').pop(),
        }))
        dispatch(setList(data));
    }

    useEffect(() => {
        loadStorage();
    }, [selectedExpedient.id]);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 col-lg-4 col-xl-4 box-upload-files pt-5">
                    <DropFiles files={files} setFiles={setFiles} handleSubmit={handleSubmit} />
                </div>

                <div className="col-12 col-lg-8 col-xl-7 ms-2">
                    <h6 className="font-rubik fw-normal mb-3">Archivos Cargados</h6>
                    <div className="uploaded-files">
                        {
                            list?.length === 0 ?
                                <div className="alert bg-light-purple text-center" role="alert">
                                    No hay archivos externos en este expediente
                                </div>
                                :
                                list.map((file) => <CardFileUploaded
                                    key={file.id}
                                    file={file}
                                    onDelete={() => confirmDelete(() => deleteItemStorage(file.id))}
                                    onEdit={() => {
                                        dispatch(setSelectedFile(file));
                                        setShow(true);
                                    }}
                                />)
                        }
                    </div>
                </div>
            </div>

            <ModalStorageEdit show={show} setShow={setShow} onSubmited={loadStorage} />
        </div>
    )
}
