import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';

import DynamicTable from '../../../TercerosCreate/components/DynamicTable';
import { SelectAndCreate } from '../../../TercerosCreate/components/SelectAndCreate';
import { SearchBarPeople } from '../../../TercerosCreate/components/SearchBarPeople';

import GeneralService from '../../../../services/GeneralService';
import { insertFromPart, reducerForm, updateFromPart } from '../../../../reducers/expedient/reducerParts';
import { TOAST_DANGER, HTTP_OK, ID_NOT_DEFINED } from '../../../../constant/constant';
import { MessageError, toast } from '../../../../utils';
import { loadOptionLabel } from '../../parameterization/utils/utils';
import { reactSelectStyles } from '../styles/reactSelectStyles';

export const ModalPart = ({ show, setShow, people }) => {
    const { selectedExpedient } = useSelector(state => state.expedientSlice);
    const { selectedPart } = useSelector(state => state.partsSlice);
    const { list } = useSelector(state => state.labelGlobalSlice);
    const dispatch = useDispatch();
    const history = useHistory()
    const [showSelectClient, setShowSelectClient] = useState(false);

    const setData = (e) => {
        dispatch(reducerForm({
            key: e.target.name,
            value: e.target.value,
        }));
    }

    const handleSelectedLabel = (selected, actionMeta) => {
        dispatch(reducerForm({
            key: "label_global_id",
            value: selected.value,
        }));
    }

    const onSelectPerson = (person) => {
        dispatch(reducerForm({ key: "people_id", value: person.id }));
        dispatch(reducerForm({ key: "people", value: person }));
        setShowSelectClient(false);
    }

    const loadPeople = async () => {
        if (people !== undefined && parseInt(people) > 0) {
            const service = new GeneralService("people/" + people);
            const res = await service.getRequest();
            if (service.status === HTTP_OK) {
                toast(dispatch, "Ya estás listo para continuar");
                return onSelectPerson(res);
            }
            history.push({ search: "?tab=parts" });
            MessageError("La persona especificada no existe");
        }
    }

    const onViewPerson = () => {
        window.open("/people/edit/" + selectedPart.people_id);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedPart.people?.name) return toast(dispatch, "Elige una persona para continuar", TOAST_DANGER);
        const service = new GeneralService("part");

        const data = {
            ...selectedPart,
            people: undefined,
            expedients_id: selectedExpedient.id,
        }

        if (selectedPart.id === ID_NOT_DEFINED) {
            const res = await service.post(data);
            if (res.is_ok) {
                setShow(false);
                toast(dispatch, res.message);
                dispatch(insertFromPart({ ...selectedPart, id: res.part?.id }));
                history.push({ search: "?tab=parts" });
                return;
            }
            MessageError(res.message, service.status);
            return;
        }

        const res = await service.update(data);
        if (res.is_ok) {
            setShow(false);
            toast(dispatch, res.message);
            dispatch(updateFromPart(selectedPart));
            return;
        }
        MessageError(res.message, service.status);
        return;
    }

    useEffect(() => {
        loadPeople();
    }, [people]);

    return (
        <Modal show={show} onHide={() => setShow(false)} size='xl'>
            <form onSubmit={handleSubmit} autoCorrect="on">
                <Modal.Header className='bg-green pe-4' closeButton>
                    {
                        selectedPart.id === ID_NOT_DEFINED ?
                            <i className="fa-solid fa-circle-plus animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                            :
                            <i className="fa-solid fa-pen-to-square animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                    }
                    <h5 className="mt-2 ms-3">
                        {
                            selectedPart.id === ID_NOT_DEFINED ?
                                "Crear Parte"
                                :
                                "Editar Parte"
                        }
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">

                        <div className="row mb-3">
                            <div className='col'>
                                <label className="font-rubik">Persona<b>
                                    <i className="text-red">*</i></b>
                                    {
                                        selectedPart.people_id !== ID_NOT_DEFINED
                                        &&
                                        <div className="d-inline-block text-decoration-underline text-purple cursor-pointer ms-2" onClick={onViewPerson} >
                                            Ver información de la persona <i className="fa-solid fa-square-up-right"></i>
                                        </div>
                                    }
                                </label>
                                <input
                                    type="text"
                                    className="form-control mt-1 green-input"
                                    name="people"
                                    value={selectedPart.people?.name}
                                    placeholder="Click para seleccionar persona"
                                    required
                                    readOnly
                                    onClick={() => setShowSelectClient(!showSelectClient)}
                                />
                            </div>
                        </div>
                        {
                            showSelectClient
                            &&
                            <div className="row">
                                <div className="table-data">
                                    <div className='container-fluid'>
                                        <div className='row'>
                                            <SelectAndCreate
                                                showCreateLinkButton={false}
                                                pathCreate={`/people/create?next=/expedient/edit/${selectedExpedient.id}?tab=parts`}
                                            />
                                        </div>
                                        <div className='row mt-3'>
                                            <SearchBarPeople forParameterization={false} />
                                        </div>
                                        <div className='row mt-2' style={{ height: "20rem", overflowY: "auto" }}>
                                            <DynamicTable
                                                actionName="Seleccionar"
                                                forParameterization={false}
                                                onPersonSelected={onSelectPerson}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="row">
                            <div className='col mb-3'>
                                <label className="font-rubik">Etiqueta<b><i className="text-red">*</i></b></label>
                                <Select
                                    options={list.map(itemLabel => {
                                        return {
                                            value: itemLabel.id,
                                            label: itemLabel.name,
                                        }
                                    })}
                                    value={loadOptionLabel(list, selectedPart)}
                                    onChange={handleSelectedLabel}
                                    styles={reactSelectStyles}
                                    isSearchable={true}
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className='col mb-3'>
                                <label className="font-rubik">Observación</label>
                                <textarea
                                    className="form-control custom-textarea mt-1 green-input"
                                    name="observation"
                                    value={selectedPart.observation}
                                    maxLength={300}
                                    placeholder="Ingrese una observación"
                                    onChange={setData}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" className={`btn btn-success mx-auto rounded-3 button-save-document'`}>
                        <i className="fa-solid fa-paper-plane me-2"></i>
                        {
                            selectedPart.id === ID_NOT_DEFINED ?
                                "Agregar"
                                :
                                "Actualizar"
                        }
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}