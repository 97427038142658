import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import ModalOpenQuestion from "./ModalOpenQuestion";
import DialogsBySteps from "./DialogsBySteps";
import IconButton from "../../../app/components/IconButton";
import CardAnswer from "../../../app/components/CardAnswer";

import { TOAST_DANGER, ID_NOT_DEFINED, TYPE_ANSWER } from "../../../constant/constant";
import { addListAnswers, deleteListAnswers, setGeneratedProcess, setSelectedGenerateAnswer } from "../../../reducers/forms/reducerCreateProcessForm";
import { toast } from "../../../utils/toast";
import confirmDelete from "../../../utils/confirmDelete";
import { hideLoading, showLoading } from "../../../reducers/main/loadingReducer";
import GeneralService from "../../../services/GeneralService";
import { validateDataForm } from "../utils/extract";
import { MessageConfirm, MessageError } from "../../../utils/message";
import { saveProgress } from "../utils/persistence";

const TabDialogs = ({ setCurrentStep }) => {
    const { listQuestions, listAnswers, generatedProcess, dataForm, listActors, checkCodes, optionsZapsign } = useSelector(state => state.createProcessFormSlice);
    const { selectedForm } = useSelector(state => state.formSlice);
    const { loading } = useSelector(state => state.loadingStateSlice);
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const answersRef = useRef(null);

    const onTapCode = (obj, type) => {
        if (listAnswers.some(answer => answer.code_question_id === obj.code_question_id)) {
            toast(dispatch, "Respuesta agregada antes", TOAST_DANGER);
            return;
        }
        if (type === TYPE_ANSWER) {
            dispatch(addListAnswers({ value: { ...obj, type, answer_text: obj.answer, type_answer: obj.name, answers_id: obj.id, id: 0 } }));
            toast(dispatch, "Respuesta agregada");
            scrollToBottom();
            return;
        }

        dispatch(setSelectedGenerateAnswer({
            value: {
                ...obj,
                type,
                code_question_id: obj.code_questions.id,
                answer_text: "",
                contextual_text: obj.answer,
                id: 0,
            }
        }));

        setShow(true);
    }

    const handleDelete = async (item) => {
        dispatch(deleteListAnswers({ value: item.code_questions.id }));
        toast(dispatch, "Eliminado correctamente");
    }

    const registerProcess = async () => {
        if (loading) {
            return;
        }

        if (generatedProcess.id === ID_NOT_DEFINED) {
            const wantContinue = await MessageConfirm("Revisa los datos", `Revisa muy bien tus datos antes de enviarlos. Una vez enviados no los puedes modificar. ¿Deseas continuar de todos modos?`);
            if (!wantContinue) {
                return;
            }

            const isOk = await sendDataForm();
            if (!isOk) {
                return;
            }
        }

        setCurrentStep(4);
    };


    const sendDataForm = async () => {
        let data = {
            form_id: selectedForm.id,
            data_form: [],
            actors: [],
            answers: [],
        }

        // Validate data form
        const { listData, omit, isOk } = await validateDataForm(dataForm);
        if (!omit || !isOk) {
            return false;
        }

        data.data_form = listData;

        // Validate data actors
        for (let index = 0; index < listActors.length; index++) {
            const { listData, omit, isOk } = await validateDataForm(listActors[index].data);
            if (!omit || !isOk) {
                return false;
            }
            data.actors.push({ ...listActors[index], data: listData })
        }
        data.answers = listAnswers;

        dispatch(showLoading());
        const service = new GeneralService("process-form");
        const res = await service.post(data);
        dispatch(hideLoading());
        if (res.is_ok) {
            dispatch(setGeneratedProcess({ value: res.process }));
            saveProgress({
                process: res.process,
                listAnswers, dataForm, listActors, optionsZapsign, checkCodes
            });
            return res.is_ok;
        }
        await MessageError(res.message, service.status);

        return false;
    }

    const scrollToBottom = () => {
        if (answersRef.current) {
            setTimeout(() => {
                answersRef.current.scrollTop = answersRef.current.scrollHeight;
            }, 100);
        }
    };

    return (
        <>
            <div className="process-tab">
                <div className="row">
                    <div className="col-12 col-lg-7 border-right-dashed mb-3">
                        <div className="row">
                            <div className="col">
                                <h5><b>Diálogos</b></h5>
                            </div>
                        </div>
                        <p>Tienes {listQuestions.length} preguntas para responder. Cada una tiene su respectiva secuencia, asegúrate de responderlas en su totalidad.</p>

                        <DialogsBySteps onTapCode={onTapCode} />
                    </div>
                    <div className="col-12 col-lg-5 px-3" style={{ position: "relative" }}>
                        <div className="row">
                            <div className="col fw-bold">
                                <h5 className="text-purple font-lilita-one pb-2">
                                    <i className="fa-solid fa-circle-nodes me-2"></i>
                                    Respuestas
                                </h5>
                            </div>
                        </div>
                        <div ref={answersRef} className="row" style={{ maxHeight: "24.5rem", overflowY: "auto" }}>
                            <div className="col">
                                {
                                    listAnswers.length === 0
                                        ?
                                        <div className="alert alert-warning text-center mt-1" role="alert">
                                            No has respondido a ninguna pregunta
                                        </div>
                                        :
                                        listAnswers.map(item => <CardAnswer
                                            key={item.code_question_id}
                                            item={item}
                                            actions={
                                                <>
                                                    <IconButton
                                                        icon="fa-solid fa-edit text-green"
                                                        title="Editar respuesta"
                                                        onClick={() => {
                                                            dispatch(setSelectedGenerateAnswer({ value: { ...item, edit: true } }));
                                                            setShow(true);
                                                        }}
                                                    />
                                                    <IconButton
                                                        icon="fa-solid fa-trash text-red"
                                                        title="Eliminar respuesta"
                                                        onClick={async () => confirmDelete(() => handleDelete(item))}
                                                    />
                                                </>
                                            }
                                        />)
                                }

                            </div>
                        </div>
                        <div className="row my-4" />
                        <div className="row" style={{ position: "absolute", bottom: "0px", right: "10px" }}>
                            <div className="col mt-5">
                                <button type="submit" className="btn btn-success rounded-3 d-block ms-auto px-3 py-2" onClick={registerProcess} >
                                    Siguiente paso <i className="fa-solid fa-caret-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalOpenQuestion show={show} setShow={setShow} handleScrollAnswers={scrollToBottom} />
        </>
    );
}

export default TabDialogs;