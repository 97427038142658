import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GeneralService from "../../services/GeneralService";
import {
  setFormConfigEmail,
  saveConfigEmail,
} from "../../reducers/customMail/reducerCustommail";
import { MessageError, toast } from "../../utils";
import { TOAST_SUCCESS } from "../../constant/constant";

const TabEmail = () => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isConnectionTested, setIsConnectionTested] = useState(false);
  const dispatch = useDispatch();
  const { configEmail } = useSelector((state) => state.customMailSlice);

  useEffect(() => {
    if (showConfirmation) {
      const timer = setTimeout(() => {
        setShowConfirmation(false);
      }, 8000);

      return () => clearTimeout(timer);
    }
  }, [showConfirmation]);

  const setData = (e) => {
    dispatch(
      setFormConfigEmail({
        key: e.target.name,
        value: e.target.value,
      })
    );
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = { ...configEmail, port: parseInt(configEmail.port) };
    const service = new GeneralService("custom-email");
    const res = await service.post(data);
    if (res.is_ok) {
      dispatch(saveConfigEmail(res.content));
      toast(dispatch, res.message, TOAST_SUCCESS, 5000)
      return;
    }
    MessageError(res.message);
  };

  const handleTestConnection = async () => {
    const service = new GeneralService("custom-email/verify");
    let data = { ...configEmail, port: parseInt(configEmail.port) };
    const res = await service.post(data);
    if (res.is_ok) {
      setShowConfirmation(true);
      setIsConnectionTested(true);
    } else {
      MessageError(res.message);
    }
  };

  return (
    <>
      <div className="divemail">
        <div className="divemail-title">
          <i
            className="fa-solid fa-envelope"
            style={{
              display: "flex",
              flexwrap: "wrap",
              aligncontent: "center",
              marginRight: "10px",
              fontSize: "26px",
            }}
          />
          <div className="divemail-titlelabel">
            Personalice su dirección de correo electrónico
          </div>
        </div>
        <div className="divemail-body">
          <div>
            Configure los detalles del correo electrónico para enviar y recibir
            notificaciones relacionadas con sus expedientes y movimientos.
          </div>

          <form className="divemail-bodyform" onSubmit={handleSubmit}>
            <input
              value={configEmail.email}
              className="divemail-bodyinput"
              type="email"
              placeholder="  Correo electronico"
              name="email"
              onChange={setData}
            />
            <input
              value={configEmail.smtp}
              type="text"
              className="divemail-bodyinput"
              placeholder="  Servidor SMTP"
              name="smtp"
              onChange={setData}
            />
            <input
              value={configEmail.port}
              type="number"
              className="divemail-bodyinput"
              placeholder="  Puerto"
              name="port"
              onChange={setData}
            />
            <input
              value={configEmail.username}
              type="text"
              className="divemail-bodyinput"
              placeholder="  Nombre de usuario"
              name="username"
              onChange={setData}
            />
            <input
              value={configEmail.password}
              type="password"
              className="divemail-bodyinput"
              placeholder="  Contraseña"
              name="password"
              onChange={setData}
            />
            <div>
              <div className="divemail-footcontainer">
                <button
                  type="submit"
                  className="divemail-button1"
                  disabled={!isConnectionTested}
                  style={{
                    opacity: isConnectionTested ? 1 : 0.5,
                    cursor: isConnectionTested ? "pointer" : "not-allowed",
                  }}
                >
                  Guardar configuración
                </button>
                <button
                  type="button"
                  onClick={handleTestConnection}
                  className="divemail-button2"
                >
                  Probar conexión
                </button>
              </div>
              {showConfirmation && (
                <div
                  className="confirmation-message"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "15px",
                    backgroundColor: "#d4edda",
                    color: "#000000",
                    borderRadius: "4px",
                    height: "40px",
                  }}
                >
                  <i
                    className="fa-solid fa-check-circle"
                    style={{ marginRight: "5px", color: "#155724" }}
                  />
                  Configuración probada con éxito
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default TabEmail;
