import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import IconButton from '../../../app/components/IconButton';
import CardActorViewSignature from './CardActorViewSignature';

import { formatDateTime } from '../../../utils/formatDate';
import { isURL } from '../../../utils/isUrl';
import { toast } from '../../../utils/toast';
import { TOAST_WARNING } from '../../../constant/constant';
import { useDownloadFile } from '../../../app/hooks/useDownloadFile';

export const ListAllGeneratedDocuments = ({ onSelectedDocument }) => {
    const { elatedDocumentsFiltered } = useSelector(state => state.personDocumentSlice);

    return (
        <>
            <div className="row">
                {
                    elatedDocumentsFiltered.length === 0
                        ?
                        <div className="alert alert-warning text-center mt-2" role="alert">
                            No hay documentos que mostrar
                        </div>
                        :
                        <div className="d-block rounded-3 clip-hide">
                            <table className={`table table-responsive table-header-custom animate__animated animate__fadeIn ${elatedDocumentsFiltered.length > 0 && "table-striped"}`}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>#</th>
                                        <th>Tipo de documento</th>
                                        <th>Fecha</th>
                                        <th>Estado</th>
                                        <th>Información</th>
                                        <th>Seleccionar</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        elatedDocumentsFiltered.map((document, i) => (<RowDocument
                                            key={document.id}
                                            generatedDocument={document}
                                            index={i}
                                            isPerPerson={false}
                                            onSelectedDocument={onSelectedDocument}
                                        />))
                                    }
                                </tbody>
                            </table>
                            {/* <SignatureLegend /> */}
                        </div>
                }
            </div>
        </>
    )
}

const RowDocument = ({ generatedDocument, index, onSelectedDocument }) => {
    const [show, setShow] = useState(false);
    const history = useHistory();    
    const handleDownload = useDownloadFile();
    const dispatch = useDispatch();
    const rowRef = useRef(null);

    const signedFile = () => {
        if (isURL(generatedDocument.url_signed_file)) {
            window.open(generatedDocument.url_signed_file);
            return;
        }
        toast(dispatch, "Este documento no ha sido firmado por nadie", TOAST_WARNING)
    }

    const settingStatus = (doc) => {
        if (doc.needs_zapsign) {
            const signers = doc.actors?.length;
            let counter = 0;
            doc.actors?.forEach(actor => {
                if (actor.status === "signed") counter++;
            });

            return doc.status === "signed"
                ?
                <><i role="button" title="Firmado" className="fa-solid fa-file-circle-check text-success"></i> {counter}/{signers}</>
                :
                <><i role="button" title="No firmado" className="fa-solid fa-file-circle-xmark text-orange"></i> {counter}/{signers}</>;
        }

        return <div className="fw-bold">SIN FIRMAS</div>;
    }

    const popover = (
        <Popover style={{ minWidth: "25rem", maxHeight: "25rem", overflowY: "auto", overflowX: "hidden" }}>
            <Popover.Header className="bg-purple text-white text-center" as="h3">Actores del documento</Popover.Header>
            <Popover.Body>
                <div className="container">
                    {
                        generatedDocument.actors?.map(actor => <CardActorViewSignature
                            key={actor.id}
                            actor={actor}
                            label={actor.label?.name}
                        />)
                    }
                </div>
            </Popover.Body>
        </Popover>
    );

    const handleRowClick = (e) => {
        if (rowRef.current && !rowRef.current.contains(e.target)) {
            setShow(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleRowClick);
        return () => {
            document.removeEventListener("click", handleRowClick);
        };
    }, []);

    return (
        <tr ref={rowRef} onDoubleClick={() => onSelectedDocument(generatedDocument)}>
            <td></td>
            <td style={{width: "40px"}}>{index + 1}.</td>
            <td style={{ maxWidth: "300px" }}>{generatedDocument.documents_auto.name}</td>
            <td>{formatDateTime(generatedDocument.created_at)}</td>
            <td style={{ maxWidth: "400px" }}>
                {settingStatus(generatedDocument)}
            </td>
            <td>
                <OverlayTrigger placement="left" overlay={popover} show={show}>
                    <button title="Ver actores del documento" className='bg-icon_button' onClick={() => setShow(!show)} style={{ border: "none" }}>
                        <i className="fa-solid fa-person-rays text-purple"></i>
                    </button>
                </OverlayTrigger>
                <IconButton
                    icon="fa-solid fa-file-arrow-down text-green"
                    title="Descargar documento"
                    onClick={() => handleDownload(generatedDocument.url_pdf)}
                />
                {
                    generatedDocument.needs_zapsign &&
                    <IconButton
                        icon="fa-solid fa-file-contract text-purple"
                        title="Descargar documento firmado"
                        onClick={signedFile}
                    />
                }
                <IconButton
                    icon="fa-solid fa-circle-info text-orange"
                    title="Visualizar documento"
                    onClick={() => handleDownload("/documents-auto/view/" + generatedDocument.id)}
                />
            </td>
            <td>
                <IconButton
                    icon="fa-solid fa-plug text-white"
                    className='bg-green-dark ms-4'
                    title="Asociar este documento"
                    onClick={() => onSelectedDocument(generatedDocument)}
                />
            </td>
            <td></td>
        </tr>
    )
}